import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/svgs/icon_locale.svg'


import { locales } from '@/config/constants/locale';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const store = useStore();
function setLocale(locale: Nullable) {
	store.commit('system/SET_LOCALE', { lang: locale.name });
}

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    class: "locale",
    trigger: "click"
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, { class: "my-drop-menu" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(locales), (item) => {
            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
              key: item.name,
              class: "my-drop-menu-item",
              onClick: ($event: any) => (setLocale(item))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "el-dropdown-link" }, [
        _createElementVNode("img", {
          class: "icon",
          src: _imports_0,
          alt: ""
        })
      ], -1))
    ]),
    _: 1
  }))
}
}

})