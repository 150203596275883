import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from './../../assets/images/icons8.png'


import { logouts } from '@/config/constants/logout';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Dropdown',
  setup(__props) {

const router = useRouter();
const store = useStore();

const forceLogout = () => {
	store.commit('auth/setClearToken');

	if (store.state.showMode === 'webview') {
		window.Java.jsSignOut();

		router.push({ name: 'home', query: { showMode: 'webview', isLogin: 0 } });
	} else {
		// router.push('/login');
		window.location.href = '/';
	}
};

return (_ctx: any,_cache: any) => {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    class: "locale",
    trigger: "click"
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, { class: "my-drop-menu" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(logouts), (item) => {
            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
              key: item.name,
              class: "my-drop-menu-item",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (forceLogout()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "login-box" }, [
        _createElementVNode("div", null, [
          _createElementVNode("img", {
            class: "my-img",
            src: _imports_0
          })
        ])
      ], -1))
    ]),
    _: 1
  }))
}
}

})