import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "appMain",
  class: "wrap"
}
const _hoisted_2 = {
  key: 0,
  class: "h-20"
}

import { useStore } from 'vuex';
import store from '@/store';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import Header from '@/components/common/HeaderView.vue';
import Footer from '@/components/common/FooterView.vue';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const vuexStore = useStore();
const bannerList = store.getters['auth/getBannerList'];
const nftList = store.getters['auth/getNftList'];

onMounted(async () => {
	if (!bannerList) {
		await vuexStore.dispatch('auth/getBannerList', store);
	}

	if (!nftList) {
		await vuexStore.dispatch('auth/getNftList', store);
	}
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
			_ctx.$route.name !== 'home' &&
			_ctx.$route.name !== 'terms' &&
			_ctx.$route.name !== 'Tutorial'
		)
      ? (_openBlock(), _createBlock(Header, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      (_ctx.$route.name !== 'Tutorial')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    (
			_ctx.$route.name !== 'home' &&
			_ctx.$route.name !== 'terms' &&
			_ctx.$route.name !== 'Tutorial'
		)
      ? (_openBlock(), _createBlock(Footer, { key: 1 }))
      : _createCommentVNode("", true),
    (_unref(store).state.isLoading)
      ? (_openBlock(), _createBlock(LoadingSpinner, { key: 2 }))
      : _createCommentVNode("", true),
    (_unref(store).state.isRouterLoading)
      ? (_openBlock(), _createBlock(LoadingSpinner, { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})