import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo.png'
import _imports_1 from '@/assets/images/sns05_off.png'


const _hoisted_1 = { class: "w-full flex justify-between items-center" }
const _hoisted_2 = { class: "wp-30 flex" }
const _hoisted_3 = { class: "wp-30 login-box" }
const _hoisted_4 = { class: "flex justify-end items-center" }
const _hoisted_5 = {
  class: "wp-80 p-5 flex flex-col justify-start items-start",
  style: {"background":"#fff"}
}
const _hoisted_6 = { class: "mb-6 underline" }
const _hoisted_7 = { class: "text-2xl text-left" }
const _hoisted_8 = { class: "text-2xl" }

import { useRouter } from 'vue-router';
import { ref, computed } from 'vue';
import Dropdown from './Dropdown.vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderView',
  setup(__props) {

const store = useStore();
const router = useRouter();

const isPopup = ref(false);

const userName = computed(() => store.state.auth.userName);
const userEmail = computed(() => store.state.auth.userEmail);

const menuVisible = ref(false);
const aniVisible = ref(false);

const serviceModal = () => {
	store.state.popupType = 'message';
	isPopup.value = true;
};

const Withdrawal = () => {
	router.push({
		path: '/Withdraw',
		name: 'Withdraw',
	});
	toggleMenu();
};

const toggleMenu = () => {
	if (menuVisible.value == true) {
		aniVisible.value = false;

		setTimeout(() => {
			menuVisible.value = false;
		}, 100);
	} else {
		menuVisible.value = true;

		setTimeout(() => {
			aniVisible.value = true;
		}, 100);
	}
};

const movePage = (page: string) => {
	menuVisible.value = false;
	aniVisible.value = false;

	router.push(page).catch(err => {
		console.error('Routing error:', err);
	});
};

const services = ref([
	{
		title: 'Web',
		link: '#',
	},
	{
		title: 'Design',
		link: '#',
	},
	{
		title: 'Videos',
		link: '#',
	},
]);

// const openTelegram = () => {
// 	window.flutter_inappwebview
// 		.callHandler('handleTelegramCommBtn')
// 		.then((res: any) => {
// 			console.log(res);
// 		});
// };

const openKakaoTalk = () => {
	window.flutter_inappwebview
		.callHandler('handleKakaoCommBtn')
		.then((res: any) => {
			console.log(res);
		});
};

const closeModal = () => {
	isPopup.value = false;
};

return (_ctx: any,_cache: any) => {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "header",
      class: _normalizeClass({ open: menuVisible.value === true })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("button", {
            type: "button",
            class: "menu-btn",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleMenu()))
          }, _cache[14] || (_cache[14] = [
            _createElementVNode("span", null, null, -1)
          ]))
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "wp-40 flex justify-center" }, [
          _createElementVNode("h1", null, [
            _createElementVNode("a", { href: "/" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "ZeroQuest"
              })
            ])
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "w-5" }, null, -1)),
            _createVNode(Dropdown, {
              title: "Services",
              items: services.value
            }, null, 8, ["items"])
          ])
        ])
      ])
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["menus flex", { ani: aniVisible.value === true }])
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(userName.value), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(userEmail.value), 1)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl", { select: _ctx.$route.name === 'myzq' }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (movePage('/myzq')))
        }, " My Z.Q ", 2),
        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "h-6" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl", { select: _ctx.$route.name === 'zeronft' }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (movePage('/zeronft')))
        }, " ZeroNFT ", 2),
        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "h-6" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl", { select: _ctx.$route.name === 'onft' }]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (movePage('/onft')))
        }, " PlayNFT ", 2),
        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "h-6" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl", { select: _ctx.$route.name === 'mywallet' }]),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (movePage('/mywallet')))
        }, " MyPoint ", 2),
        _cache[27] || (_cache[27] = _createElementVNode("div", { class: "h-6" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl", { select: _ctx.$route.name === 'market' }]),
          onClick: _cache[5] || (_cache[5] = ($event: any) => (movePage('/market')))
        }, " Eco Market ", 2),
        _cache[28] || (_cache[28] = _createElementVNode("div", { class: "h-6" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl", { select: _ctx.$route.name === 'challenge' }]),
          onClick: _cache[6] || (_cache[6] = ($event: any) => (movePage('/challenge')))
        }, " Challenge ", 2),
        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "h-8" }, null, -1)),
        _cache[30] || (_cache[30] = _createElementVNode("div", { class: "text-2xl font-semibold" }, "About", -1)),
        _cache[31] || (_cache[31] = _createElementVNode("div", { class: "h-3" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["mb-2 text-2xl w-full flex", { select: _ctx.$route.name === 'introduction' }]),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (movePage('/introduction')))
        }, _cache[17] || (_cache[17] = [
          _createElementVNode("div", { class: "wp-70 flex justify-start" }, "About ZeroQuest", -1),
          _createElementVNode("div", { class: "wp-30 flex justify-end menu-right" }, "→", -1)
        ]), 2),
        _createElementVNode("div", {
          class: _normalizeClass(["mb-2 text-2xl w-full flex", { select: _ctx.$route.name === 'TermsNotion' }]),
          onClick: _cache[8] || (_cache[8] = ($event: any) => (movePage('/TermsNotion')))
        }, _cache[18] || (_cache[18] = [
          _createElementVNode("div", { class: "wp-70 flex justify-start" }, "이용 약관 확인", -1),
          _createElementVNode("div", { class: "wp-30 flex justify-end menu-right" }, "→", -1)
        ]), 2),
        _createElementVNode("div", {
          class: _normalizeClass(["mb-2 text-2xl w-full flex", { select: _ctx.$route.name === 'TutorialScroll' }]),
          onClick: _cache[9] || (_cache[9] = ($event: any) => (movePage('/TutorialScroll')))
        }, _cache[19] || (_cache[19] = [
          _createElementVNode("div", { class: "wp-70 flex justify-start" }, "튜토리얼 다시보기📖", -1),
          _createElementVNode("div", { class: "wp-30 flex justify-end menu-right" }, "→", -1)
        ]), 2),
        _cache[32] || (_cache[32] = _createElementVNode("div", { class: "h-3" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl w-full flex", { select: _ctx.$route.name === 'terms' }]),
          onClick: _cache[10] || (_cache[10] = ($event: any) => (movePage('/terms')))
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl w-full flex", { select: _ctx.$route.name === 'terms' }]),
          onClick: _cache[11] || (_cache[11] = ($event: any) => (movePage('/terms')))
        }, null, 2),
        _cache[33] || (_cache[33] = _createElementVNode("div", { class: "h-6" }, null, -1)),
        _cache[34] || (_cache[34] = _createElementVNode("div", { class: "pb-2 text-2xl font-semibold" }, "사용방법문의", -1)),
        _createElementVNode("div", {
          onClick: openKakaoTalk,
          class: "cursor-pointer"
        }, _cache[20] || (_cache[20] = [
          _createElementVNode("img", {
            src: _imports_1,
            class: "w-10"
          }, null, -1)
        ])),
        _cache[35] || (_cache[35] = _createElementVNode("div", { class: "h-10" }, null, -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["text-2xl font-semibold text-red-600", { select: _ctx.$route.name === 'NoticeEvent' }]),
          onClick: _cache[12] || (_cache[12] = ($event: any) => (movePage('/NoticeEvent')))
        }, " 공지사항&이벤트 ", 2),
        _createElementVNode("div", { class: "fixed bottom-0 text-left wp-70 mb-4" }, [
          _cache[21] || (_cache[21] = _createElementVNode("div", null, "(주) 제로퀘스트 대표 한해수", -1)),
          _cache[22] || (_cache[22] = _createElementVNode("div", null, "대구광역시 중구 종로 45-4, 3층 3866호(종로1가)", -1)),
          _cache[23] || (_cache[23] = _createElementVNode("div", null, "사업자등록번호 : 783-87-03063", -1)),
          _createElementVNode("div", {
            class: "mt-4 bg-gray-600 border border-gray-300 font-medium p-1.5 text-xl text-white text-center",
            onClick: Withdrawal
          }, " 회원탈퇴 ")
        ])
      ]),
      _createElementVNode("div", {
        class: "wp-20",
        onClick: _cache[13] || (_cache[13] = ($event: any) => (toggleMenu()))
      })
    ], 2), [
      [_vShow, menuVisible.value]
    ]),
    _createVNode(_component_Modal, {
      visible: isPopup.value,
      onHide: closeModal,
      title: "서비스 준비중"
    }, null, 8, ["visible"])
  ], 64))
}
}

})